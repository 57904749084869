$(document).ready(function () {
    const helpmodal = document.getElementById('help')
    if (helpmodal !== null) {
        helpmodal.addEventListener('show.bs.modal', function (event) {
            const setModalContent = (header, body, footer = '') => {
                helpmodal.querySelector('.modal-title').innerHTML = header;
                helpmodal.querySelector('.modal-body').innerHTML = body;
                helpmodal.querySelector('.modal-footer').innerHTML = footer;
            }

            const getData = (url, callback) => {
                $.getJSON(url, function (data) {
                    callback(data);
                });
            }

            setModalContent("unavailable", "unavailable", "unavailable");

            const button = event.relatedTarget;
            const reference = button.getAttribute('data-bs-reference');
            const page = button.getAttribute('data-bs-page');

            if (reference === 'page'){
                setModalContent('Help', '');
                getData(`/help_items/find_page.json?p=${page}`, function(data) {
                    let help = "";
                    $.each(data, function(key, val) {
                        help += val.header;
                        help += val.body;
                    });
                    helpmodal.querySelector('.modal-body').innerHTML = help;
                });
            } else {
                getData(`/help_items/find_reference.json?r=${reference}&p=${page}`, function(data) {
                    setModalContent(
                        data[0]?.header || "",
                        data[0]?.body || "",
                        data[0]?.footer || ""
                    );
                });
            }
        });
    }
});