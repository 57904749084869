require('easy-autocomplete')
document.addEventListener("turbolinks:load", function() {
    $(document).ready(function() {
        var totalTimeVar = "Previous total: " + $('#totalTimeDiv').data('totalTime');
        if ($('#logbook_book_type_id').val() === "") {
            $('#logbook_total_time').attr('placeholder', '');
        } else {
            var defaultPlaceholder = $('#logbook_total_time').data('defaultPlaceholder');
            $('#logbook_total_time').attr('placeholder', defaultPlaceholder);
        }

        $('#logbook_book_type_id').change(function() {
            if ($(this).val() === "") {  // if the select is empty
                $('#logbook_total_time').attr('placeholder', '');  // set the placeholder of the input to blank
            } else {
                var defaultPlaceholder = $('#logbook_total_time').attr('data-default-placeholder');
                $('#logbook_total_time').attr('placeholder', totalTimeVar);

            }
        });
        $('#logbook_book_type_id').change(function() {
            var work_item_id = $(this).val();
            var aircraft_id = $('#aircraftDiv').data('aircraft-id');
            var dropdownValue = $('#logbook_book_type_id').val();
            let myVariable;
            $.ajax({
                url: "/aircrafts/" + aircraft_id + "/components/book_components",
                method: "GET",
                data: {book_type_id: dropdownValue},
                dataType: "json",
                success: function(data) {
                    $('#logbook_component_ids').find('option').remove();
                    $.each(data, function(i, item){
                        $('#logbook_component_ids').append($('<option>').text(item[1]).attr('value', item[0]));
                    });
                }
            })
        });
        $('#printLabelButton').on('click', function(e) {
            e.preventDefault();
            var content = $('#logPagePrint .modal-body').html();
            printModalContent(content);
        });
    });

    $input = $('*[data-behavior="autocomplete"]')

    var options = {
        url: function(phrase) {
            return "/works/search.json?q=" + phrase;
        },
        getValue: "name",
        list: {

            onSelectItemEvent: function() {
                var name = $("#logbook_works_attributes_0_name").getSelectedItemData().name;
                var description = $("#logbook_works_attributes_0_name").getSelectedItemData().description;
                var ad_id = $("#logbook_works_attributes_0_name").getSelectedItemData().ad;
                $("#logbook_works_attributes_0_name").val(name).trigger("change");
                $("#logbook_works_attributes_0_description").val(description).trigger("change");
                $('#logbook_works_attributes_0_ad_id').prop('value', ad_id);
            }
        }
    };
    $input.easyAutocomplete(options)
});

window.generate_description = function() {
    let hobbs = "";
    let tach = "";
    let mechanic = "";
    let shop = "";
    let comments = "";
    let aircraft = "";
    let work_comments = document.querySelectorAll("[data-behavior='work_description']");
    let length = work_comments.length;
    for (var i = 0; i < length; ++i) {
        comments = comments + work_comments[i].value +" ";
    }
    if (!$.isEmptyObject($("#logbook_hobbs").val())) {
        hobbs = "Hobbs: " + $("#logbook_hobbs").val() + " "
    }
    if (!$.isEmptyObject($("#logbook_tach").val())) {
        tach = "Tach: " + $("#logbook_tach").val() + " "
    }
    if (!$.isEmptyObject($("#logbook_mechanic").val())) {
        mechanic = "Mechanic: " + $("#logbook_mechanic").val() + " "
    }
    if (!$.isEmptyObject($("#logbook_shop").val())) {
        shop = "Shop: " + $("#logbook_shop").val() + " "
    }

    if (!$.isEmptyObject($("#logbook_n_number").val())) {
        aircraft = "Aircraft: " + $("#logbook_n_number").val() + " "
    }

    comments = aircraft + tach + hobbs + comments + mechanic + shop

    $("#logbook_description").val(comments).trigger("change")
};

// Add work item to master work item
window.add_work_item = function(elmnt) {
    var x = $(elmnt).next
    const url = "/master_work_items";
    name = $("#logbook_works_attributes_"+elmnt+"_name").val()
    ad = $("#logbook_works_attributes_"+elmnt+"_ad").val()
    description = $("#logbook_works_attributes_"+elmnt+"_description").val()
    schedule_id = $("#logbook_works_user_id_"+elmnt+"_schedule_id").val()
    aircraft_id = $("#logbook_aircraft_id").val()
    user_id = $("#logbook_user_id").val()

// post body data

    const work = {
        user_id: user_id,
        aircraft_id: aircraft_id,
        ad: ad,
        description: description,
        name: name,
        schedule_id : schedule_id,
        js: true
    }

// request options
    const options = {
        method: 'POST',
        body: JSON.stringify(work),
        headers: {
            'Content-Type': 'application/json'
        }
    }

// send POST request
    fetch(url, options)
    alert("Added")
}

window.change_id = function(elmnt) {
    console.log(elmnt);
    if (document.getElementById("click")) {
        console.log(elmnt.match(/\d+/g))
        document.getElementById("click").id = elmnt.match(/\d+/g);
    }
}
function printModalContent(content) {
    var printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
}

