$(window).on("load", function() {
    var $input = $('*[id="name_search"]');
    var options = {
        url: function(phrase) {
            return "/faa_masters/search.json?q=" + phrase;
        },
        getValue: "display",
        list: {

            onSelectItemEvent: function() {
                var name = $("#name_search").getSelectedItemData().fullname;
                var last = $("#name_search").getSelectedItemData().lastname;
                var first = $("#name_search").getSelectedItemData().firstname;
                var display = $("#name_search").getSelectedItemData().fullname;
                $("#user_last_name").val(last).trigger("change");
                $("#user_first_name").val(first).trigger("change");
                $("#user_faa_name").val(name).trigger("change");
            }
        }
    };
    console.log(("masters"))
    $input.easyAutocomplete(options);
});
