require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

var jQuery = require('jquery');
window.$ = window.jQuery = jQuery;
require("popper.js");
require("bootstrap");
require('easy-autocomplete');
require('./nested-forms/addFields');
require('./nested-forms/removeFields');
require("@fortawesome/fontawesome-free/js/all");

require("bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.min");
require("bootstrap-table/dist/extensions/mobile/bootstrap-table-mobile.min.js");
require("bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie.min.js");
require("bootstrap-table/dist/extensions/print/bootstrap-table-print.min");
require("jquery-ui");

require('bootstrap-icons/font/bootstrap-icons.scss');
//require("bootstrap-table/dist/extensions/reorder-columns/bootstrap-table-reorder-columns.min");

// import "core-js/stable"

import "packs/global_search";
//import "packs/new_schedule_modal";
import 'packs/masters'
//import "packs/ad_search"
import 'packs/logbook'
import "packs/aircraft"
//import "packs/schedule"
//import "./user"
//import "packs/book_type"
//import "packs/ad_duration"
import "./help";
import 'bootstrap-table';
import './payment';
import "cocoon"
//= require_tree .

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
});

$(document).ready(function(){
    $('[data-toggle="popover"]').popover();

});
